import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { TrackingV2Service } from '@mng-reusable/tracking/services/tracking-v2.service';
import {environment} from "@env/environment";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  abnDomain:string;

  redirect(url) {
    switch (url) {
      case 'learn-more':
        window.location.href = `${environment.protocol}://${environment.abnDomain}/learn-more`;
        break;
      case 'contact-us':
        window.location.href = `${environment.protocol}://${environment.abnDomain}/contact-us`;
        break;
      default:
        window.location.href = url;
    }
  }

  constructor(
  	private _trackingV2Service: TrackingV2Service,
  ) {
    this.abnDomain = environment.abnDomain;
  }

  ngOnInit(): void {
    this._trackingV2Service.bindGAEvents('header');

  }
}
