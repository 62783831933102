<header class="header" xmlns="http://www.w3.org/1999/html">
	<div class="header__wrapper">
		<div class="header__logo">
			<a href="https://{{abnDomain}}">
				<img src="/assets/images/header/abn3.png" class="header__logo-img"/>
			</a>
		</div>
		<div class="header__nav">
			<a class="header__nav-link" style="font-family: Sofia Pro, SofiaPro-Medium, monospace !important;" href="https://{{abnDomain}}/learn-more"
			   dirMngDefaultTracker="contact">LEARN MORE</a>
			<a class="header__nav-link" style="font-family: Sofia Pro, SofiaPro-Medium, monospace !important;"
			   href="https://{{abnDomain}}/contact-us" dirMngDefaultTracker="contact">CONTACT US</a>
			<a class="header__nav-link" href="tel:1300320283">
			<i class="material-icons header__phone-icon" >phone</i> 1300 320 283
			</a>
		</div>
		<div class="header__mobile">
			<input type="checkbox" class="openSideBarMenu" [matMenuTriggerFor]="menu" id="openSidebarMenu">
			<label for="openSidebarMenu" class="sidebarIconToggle">
				<div class="spinner diagonal part-1"></div>
				<div class="spinner horizontal"></div>
				<div class="spinner diagonal part-2"></div>
			</label>
			<mat-menu #menu="matMenu" yPosition="below" class="header__mat-menu" backdropClass="header__mat-menu-backdrop" style="">
				<div class="header__mobile-container">
					<button (click)="redirect('learn-more')" class="header__mobile-link">Learn More</button>
					<button (click)="redirect('contact-us')" class="header__mobile-link">Contact Us</button>
					<a class="header__mobile-contact" href="tel:1300320283">
						<i class="material-icons header__mobile-phone" >phone</i> 1300 320 283
					</a>
				</div>
			</mat-menu>
		</div>
	</div>
</header>
