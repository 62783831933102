import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppCustomPreloader } from './app-custom-preloader';
import { URLS } from '@mng-reusable/core/constants/urls.constant';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterComponent } from '@app/register/register.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'domain',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'pricing',
    loadChildren: () => import('@app/pricing/pricing.module').then(m => m.PricingModule)
  },
  {
    path: 'register',
    pathMatch: 'full',
    loadChildren: () => import('@app/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'renew',
    pathMatch: 'full',
    loadChildren: () => import('@app/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'renew/prefill',
    pathMatch: 'full',
    loadChildren: () => import('@app/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'search',
    pathMatch: 'full',
    loadChildren: () => import('@app/search/search.module').then(m => m.SearchModule),
  },
  {
    path: 'prefill',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('@app/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'learn-more',
    loadChildren: () => import('@app/learn-more/learn-more.module').then(m => m.LearnMoreModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('@app/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('@app/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule)
  },
  {
    path: 'success',
    loadChildren: () => import('@app/success/success.module').then(m => m.SuccessModule)
  },
  {
    path: 'how-to-get-a-virtual-address-in-sydney',
    loadChildren: () => import('@app/how-to-get/how-to-get.module').then(m => m.HowToGetModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('@app/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: 'active-ping',
    loadChildren: () => import('@app/ping/ping.module').then(m => m.PingModule)
  },
  {
    path: URLS.checkout,
    loadChildren: () => import('@mng-reusable/payment/payment.module').then(m => m.PaymentModule),
    data: { preload: true },
  },
  {
    path: 'error',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    preloadingStrategy: AppCustomPreloader,
  })],
  providers: [AppCustomPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule{}
