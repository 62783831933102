import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SeoTagsInterface } from '../interfaces/seo-tags.interface';

@Injectable({
  providedIn: 'root',
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
  ) {
  }

  generateTags(config: SeoTagsInterface): void {
    config = {
      title: 'ABN Registration',
      // tslint:disable-next-line:max-line-length
      description: 'Your ABN direct to your inbox in just 15 minutes. Trusted by more than 800,000 Australian businesses. Register today!',
      keywords: 'abnregistration, abn, abn registration, abn application, abn number, apply for abn, reactivate abn, abn register',
      ...config,
    };

    this.title.setTitle(config.title);
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'keywords', content: config.keywords });
  }

  setUncrawlablePage(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  // createLinkForCanonicalURL() {
  //   const link: HTMLLinkElement = this.doc.createElement('link');
  //   link.setAttribute('rel', 'canonical');
  //   this.doc.head.appendChild(link);
  //   link.setAttribute('href', this.doc.URL);
  // }
}
