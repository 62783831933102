<div class="not-found">
  <div class="not-found__full-wrap">
    <div class="not-found__wrapper">
      <div class="not-found__image">
        <img src="/assets/images/main/error.png" />
      </div>
      <div class="not-found__content">
        <div class="header"><span class="emphasize">Sorry,</span><br/> we could'nt find that page.</div>
        <div class="body">
          <div>The page you're looking for could have moved or you mistyped the address.</div>
        </div>
      </div>
      <div class="not-found__button">
        <button type="button" class="button button-large" (click)="backToHome()">BACK TO HOME</button>
      </div>
    </div>
  </div>
</div>