import { Component, ChangeDetectorRef } from '@angular/core';
import { Store } from '@app/services/store.service';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import { Action } from '@app/services/action';
import {GoogleTagManagerService} from "@mng-reusable/utility/google/google-tag-manager.service";
import {TrackingV3Service} from '@mng-reusable/tracking/services/tracking-v3.service';
import {URLS} from '@mng-reusable/core/constants/urls.constant';
import {Meta, Title} from "@angular/platform-browser";
import {SeoService} from "@mng-reusable/core/services/seo.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dns';
  showGlobalPreloader = false;
  hideFooter = false;
  trackCategory = '';

  constructor(
     private _store: Store,
     private _changeDetectorRef: ChangeDetectorRef,
     private _router: Router,
     private _googleTagManagerService: GoogleTagManagerService,
     private _trackingV3Service: TrackingV3Service,
     private _title: Title,
     private _meta: Meta,
     private _seoService: SeoService,
  ) {}

  ngOnInit(): void {
    this._seoService.generateTags({
      title: 'ABN Registration | ABNregistration.com.au',
      // tslint:disable-next-line:max-line-length
      description: 'Your ABN direct to your inbox in just 15 minutes. Trusted by more than 800,000 Australian businesses. Register today!',
      keywords: 'abnregistration, abn, abn registration, abn application, abn number, apply for abn, reactivate abn, abn register',
    });
    this._googleTagManagerService.initialize();
    this.initStoreEvents();
    this.initRouteEvents();
  }

  ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  private initStoreEvents(): void {
    this._store.select('layoutShowPreloader').subscribe((show: boolean) => {
      this.showGlobalPreloader = show;
    });

    this._store.select('layoutHideFooter').subscribe((hide: boolean) => {
      this.hideFooter = hide;
    });
  }

  private initRouteEvents(): void {
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.resetLayoutDefaults();
      }
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this._trackingV3Service.initializeTrackingEvents(this.trackCategory);
          this._trackingV3Service.gtagPageLoad(event.urlAfterRedirects, this.trackCategory);
        }, 10);
        window.scrollTo(0, 0);

        if (event.url.indexOf(URLS.revisit) === -1) {
          setTimeout(() => { // wait for event category to take effect
            this._store.dispatch(new Action('UPDATE', { layoutShowPreloader: false }));
          }, 50);
        }
      }
    });

  }

  private resetLayoutDefaults(): void {
    this._store.dispatch(new Action('UPDATE', { layoutShowPreloader: false }));
    this._store.dispatch(new Action('UPDATE', { layoutHideFooter: true }));
  }
}
