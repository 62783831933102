
/* tslint:disable */
export const environment = {
  production: true,
  protocol: 'https',
  activeApi: 'https://apx.honcho.com.au/39.0.0',
  bnmDomain: 'businessname.com.au',
  dnsDomain: 'domain.abnregistration.com.au',
  abnDomain: 'abnregistration.com.au',
  loggerApi: 'https://logger.honcho.be',
  ajaxTimeout: 25000,
  getIpAddress: 'https://jsonip.com/',
  bing: {
    enabled: true,
    token: '5104100'
  },
  bizcover: {
    lambdaUrl: 'https://vky4ekroy2.execute-api.ap-southeast-2.amazonaws.com/bizcover',
  },
  businessFeedRss: 'https://rss.honcho.com.au/',
  fullStory: {
    enabled: false,
    fsDebug: false,
    fsHost: 'fullstory.com',
    fsOrg: 'JRWHE',
    fsNamespace: 'FS',
  },
  luckyOrange: {
    enabled: true,
    site_id: 168251,
    source: 'https://d10lpsik1i8c69.cloudfront.net/w.js',
  },
  googleAdwords: {
    enabled: true,
    label: 'y2OXCLHttgEQl6Cc5QM',
    token: 'AW-1017581591',
  },
  googlePlaces: {
    key: 'AIzaSyAEq-0gxRBaj93VA0DBLyeFznAD4N99g5Y',
  },
  googleTagManager: {
    'trm': {
      tagManagerId: 'GTM-5MWJDC9',
    },
    analyticsId: 'UA-84542122-9',
    enabled: true,
    tagManagerId: 'GTM-5MWJDC9',
  },
  googleOptimize: {
    enabled: true,
    tagManagerId: 'GTM-5MWJDC9',
    optimizeId: 'GTM-NFPHZP2',
    analyticsId: 'UA-84542122-9',
  },
  honchoLink: 'https://honcho.com.au',
  livechat: {
    enabled: true,
    min: 0,
    max: 100000,
    license: 3817061,
  },
  logger: {
    enabled: false,
    activeFeLogger: 'https://apx.honcho.com.au/39.0.0/log/event',
    apiFeLogger: 'https://apx.honcho.com.au/39.0.0/log/api'
  },
  newRelic: {
    licenseKey: '',
    applicationId: '',
  },
  orderDetails: {
    abn: {
      enabled: true,
      serviceId: 2,
      isCsa: false,
      csa: {
        title: 'ABN Reactivation',
        description: 'Professional Tax Agent Service Fee',
        serviceFeeId: 224,
        customServiceId: 120,
        gst: 9.00,
        price: 99.00,
      }
    },
    dn: {
      enabled: true,
      serviceId: 7,
      title: 'Domain Name Registration',
      description: '',
      serviceFeeId: 265,
      renewal: {
        serviceId: 7,
        title: 'Domain Name Renewal',
        description: '',
        serviceFeeId: 335,
      }
    },
    bn: {
      enabled: true,
      description: 'Professional ASIC Agent Service Fee & Australian Securities & Investments Commission Statutory Fee at $66.33 per year',
      period: {
        oneYear: {
          gst: '9.27',
          price: '139',
          serviceFeeId: 289,
          description: 'Professional ASIC Agent Service Fee including account management, application status alerts, issues handling and renewal notifications ($102) & Australian Securities & Investments Commission Statutory Fee ($37)'       
        },
        threeYears: {
          gst: '10.18',
          price: '199',
          serviceFeeId: 290,
          description: 'Professional ASIC Agent Service Fee including account management, application status alerts, issues handling and renewal notifications ($112) & Australian Securities & Investments Commission Statutory Fee ($87)'
        }
      },
      serviceId: 1,
      title: 'Business Name Registration:'
    },
    email: {
      enabled: true,
      description: 'Professional Email Address',
      period: {
        oneYear: {
          gst: '14.18',
          price: '156.00',
          serviceFeeId: 224,
          description: 'Professional Email Address Expert Service Fee'       
        },
        oneMonth: {
          gst: '1.36',
          price: '15.00',
          serviceFeeId: 224,
          description: 'Professional email address monthly subscription.'
        }
      },
      serviceId: 26,
      title: 'Professional Email Address:'
    }
  },
  payment: {
    type: "",
    defaultOptions: 'stripe-mastercard, stripe-visa, stripe-amex, bank, bpay',
    eway: {
      enabled: true,
      encryptionKey: 'pRKkToaOGZz+6EIhVXkPc6X9ma54TspXIivpE51zrYc/m8PCpOHf30cKNMT26yrjZgLvnBi1hHTWLrAiub8XrJCn8t9NivnFSs4ciOMQAsS00n6rYpeTIYDAiVO7NOEkJA2CRotoRrSvh+CwuKHs3Gr9E6sXpnbtPt4r20WB829itoeM0OepcvofxVHaHZRjT/as3anmW5JAZF+Kk6ckb0YOG3AeZGmd5/5TeUEayckfWH9c0ukAam8Eh4wxdKBqqZHqG8bkjhooIB3TgpsJ6xqnykhrqee3kD3tfZsHstQ9aa8lLlZ3zu3g2TBb3XsbxtQjRpjkPAsXs7nrr+lsRw==',
      forceFailed: false,
    },
    fallBackEnabled: true,
    fallBackOptions: 'eway-mastercard, eway-visa, eway-amex, bank, bpay',
    paypalExpress: {
      enabled: true,
      isLive: false,
    },
    recurly: {
      enabled: false,
      publicKey: 'sjc-Zv4OsgChTFdCMgB377n4mm',
    },
    redirect_timeout: 3000,
    stripe: {
      enabled: true,
      forceFailed: false,
      publicKey: 'pk_live_VnWqLR04LTcqkHRzBWDzMJfz',
    },
    templates: {
      summary: {
        'html': './order-summary-dn.component.html',
        'css' : './order-summary-dn.component.scss'
      },
      link: {
        'html': './payment-link-dn.component.html',
        'css' : './payment-link-dn.component.scss'
      },
      modal: {
        'html': './payment-modal-dn.component.html',
        'css' : './payment-modal-dn.component.scss'
      },
      form: {
        'html': './payment-form-dn.component.html',
        'css' : './payment-form-dn.component.scss'
      }
    }
  },
  forms: {
    associate_limit: 20,
  },
  enabledService: {
    bnm: true,
    dn: true,
    gst: true,
  },
  productServices: {
    gas: {
      service: 'gas',
      waivePayment: 'yes',
    },
    businessCard: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Business Card',
      customServiceId: 34,
      notes: '',
      description: '3 free designs. Upon design approval, $99 for 100 cards',
    },
    googleListing: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Google Listing',
      customServiceId: 35,
      notes: '',
      description: 'A business profile on Google My Business',
    },
    website: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Website',
      customServiceId: 36,
      notes: '',
      description: 'Website design and launch',
    },
    email: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'Honcho Email (Annual)',
      customServiceId: 43,
      notes: '',
      description: 'Professional Email Address',
    },
    abn: {
      csa: 'csa',
      serviceFeeId: 224,
      applicationName: 'ABN Reactivation',
      customServiceId: 120,
      notes: '',
      description: 'Professional Tax Agent Service Fee',
    }
  },
  stepHeader: {
    clientDefault: 'Your details',
    dn: 'Your domain name',
    client: 'Information we have on file',
    email: 'Professional email address',
    bn: 'Business name registration',
    address: 'Address details',
  },
  tld: {
    showAll: false,
    defaultTld: 'com.au|net.au|com|au'
  },
  seo: {
    enabled: true,
  },
  serviceHostId: 22,
  splitExperiment: {
    enabled: true,
    experimentId: 'mdlsamlfwq_fqm',
    variationId: '1',
  },
  staticValues: {
    contactNumber: '1300 320 283',
  },
  version: 'default',
  wsEnabled: true,
  wsNameSpace: '/abn',
  wsUrl: 'https://wsn.masterswitch.com.au',
  tpp: {
    getRenewalStatus: 'https://apx.honcho.com.au/serverless/tpp-checker/get-renewal-status',
  },
};
/* tslint:enable */
